// eslint-disable-next-line import/prefer-default-export
export enum IconTypeNavigation {
  NAV_ACCOUNT = "nav_account",
  NAV_BBZ = "nav_bbz",
  NAV_CAMPUS = "nav_campus",
  NAV_CONTENTCREATOR = "nav_contentcreator",
  NAV_CORPORATE_LEARNING = "nav_corporate_learning",
  NAV_CYPNET = "nav_cypnet",
  NAV_EVENTS = "nav_events",
  NAV_FINTELLIGENCE = "nav_fintelligence",
  NAV_HELP = "nav_help",
  NAV_HOME = "nav_home",
  NAV_ITS = "nav_its",
  NAV_LOGOUT = "nav_logout",
  NAV_MEDIA_LIB = "nav_media_lib",
  NAV_SETTING = "nav_setting",
  NAV_SWITCH = "nav_switch",
  NAV_TESTPOOL = "nav_testpool",
  NAV_TIME2LEARN = "nav_time2learn",
  NAV_SCRAMBL_SKILLMATCHER = "nav_scrambl_skillmatcher",
  NAV_SCRAMBL_CAREERNAVIGATOR = "nav_scrambl_careernavigator",
}
