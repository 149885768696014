// eslint-disable-next-line
export enum IconDefaultType {
  /**
   * DEFAULT
   */
  ICO_ARROW = "ico_arrow", // component icons
  ICO_ARROW_OPEN_DOWN = "ico_arrow_open_down", // component icons
  ICO_ARROW_OPEN_LEFT = "ico_arrow_open_left", // component icons
  ICO_ARROW_OPEN_RIGHT = "ico_arrow_open_right", // component icons
  ICO_ARROW_OPEN_UP = "ico_arrow_open_up", // component icons
  ICO_AVATAR = "ico_avatar", // post avatar icon
  ICO_BACKWARD_STEP = "ico_backward_step", // player icons
  ICO_BOLD = "ico_bold", // editor icons
  ICO_BOLD_CLOSE = "ico_bold_close", // component icons
  ICO_CALENDAR = "ico_calendar", // calendar icon
  ICO_CAPTIONS = "ico_captions", // player icon
  ICO_CHECK = "ico_check", // button icons
  ICO_CHECKBOX_IND = "ico_checkbox_ind", // component icons
  ICO_CLASSROOM = "ico_classroom", // course attribute icons
  ICO_CLASSROOMTRAINING = "ico_classroomtraining", // LO type icons
  ICO_CLIENTSTAR = "ico_clientstar", // user icons
  ICO_CLOSE = "ico_close", // button icons
  ICO_COACH = "ico_coach", // course attribute icons
  ICO_COURSE = "ico_course", // LO type icons
  ICO_CURRENCY_CHF = "ico_currency_chf", // course attribute icons
  ICO_CURRENCY_EUR = "ico_currency_eur", // course attribute icons
  ICO_DATEPICKER = "ico_datepicker", // component attribute icons
  ICO_DELETEENTRY = "ico_deleteentry", // component attribute icons
  ICO_DELETE_THREAD = "ico_delete_thread", // empty & error
  ICO_DOCUMENT = "ico_document", // LO type icons
  ICO_DOWNLOAD = "ico_download", // LO type icons
  ICO_DROPDOWN_DOWN = "ico_dropdown_down", // component icons
  ICO_DROPDOWN_UP = "ico_dropdown_up", // component icons
  ICO_DUPLICATE = "ico_duplicate", // action icons
  ICO_EBOOK = "ico_ebook", // LO type icons
  ICO_EDIT = "ico_edit", // button icons
  ICO_EMPTY_APPOINTMENTS = "ico_empty_appointments", // empty & error
  ICO_EMPTY_CERTIFICATE = "ico_certificate_empty", // empty & error
  ICO_EMPTY_COMMUNITIES = "ico_empty_communities", // empty & error
  ICO_EMPTY_COURSES = "ico_empty_courses", // empty & error
  ICO_EMPTY_PARTICIPANTS = "ico_empty_participants", // empty & error
  ICO_EMPTY_POSTS = "ico_empty_posts", // empty & error
  ICO_ENROLL = "ico_enroll", // button icons
  ICO_EVALUATION = "ico_evaluation", // document types
  ICO_EVENT = "ico_event", // course attribute icons
  ICO_ERROR_COMMUNITIES = "ico_error_communities", // empty & error
  ICO_ERROR_COURSES = "ico_error_courses", // 120 icons
  ICO_FILE_PLUS = "ico_file_pluse", // component icons
  ICO_FORWARD_STEP = "ico_forward_step", // player icons
  ICO_FULL_AVATAR = "ico_full_avatar", // component icons
  ICO_FULL_CHECK = "ico_full_check",
  ICO_GETABSTRACT = "ico_getabstract", // LO type icons
  ICO_GLOBE = "ico_globe", // course attribute icons
  ICO_GRAPH = "ico_graph", // button icons
  ICO_HUB_NAV = "ico_hub_nav", // system icons
  ICO_IMPORTANT = "ico_important", // process and progress icons
  ICO_INDICATOR = "ico_indicator", // button icons
  ICO_INFO = "ico_info", // course attribute icons
  ICO_ITALIC = "ico_italic", // editor icons
  ICO_LIKE = "ico_like", // button icons
  ICO_LINK = "ico_link", // LO type icons
  ICO_LIST_DOT = "ico_list_dot", // editor icons
  ICO_LIST_NUM = "ico_list_num", // editor icons
  ICO_LO = "ico_lo", // LO type icons
  ICO_LOCKED = "ico_locked", // button icons
  ICO_MARKETINFO = "ico_marketinfo", // course attribute icons
  ICO_MESSAGES = "ico_messages",
  ICO_MESSAGES_UNREAD = "ico_messages_unread",
  ICO_MISSINGINFO = "ico_missinginfo", // post icon
  ICO_MIXED = "ico_mixed", // course attribute icons
  ICO_MORE_VERTICAL = "ico_more_vertical", // dashboard icon
  ICO_MULTIDEVICE = "ico_multidevice", // course attribute icons
  ICO_NO_CONNECTION = "ico_no_connection", // button icons
  ICO_OUT = "ico_out", // button icons
  ICO_OPEN_BOOK = "ico_open_book", // LO types icons
  ICO_PAUSE = "ico_pause", // player icons
  ICO_PASSED = "ico_passed", // process and progress icons
  ICO_PIN = "ico_pin", // pin icon
  ICO_PLAY = "ico_play", // button icons
  ICO_PLUS = "ico_plus", // button icons
  ICO_PROGRESS_NONE = "ico_progress_none", // component icons
  ICO_QUOTES = "ico_quotes", // editor icons
  ICO_RADIO_OFF = "ico_radio_off", // component icons
  ICO_RADIO_ON = "ico_radio_on", // component icons
  ICO_REDO = "ico_redo", // editor icons
  ICO_REPLAY = "ico_replay", // button icons
  ICO_REWIND = "ico_rewind", // player icons
  ICO_PLAY_FORWARD = "ico_play_forward", // player icons
  ICO_SCREEN = "ico_screen", // player icons
  ICO_SEARCH = "ico_search", // component icons
  ICO_SINGSELECTOR = "ico_singselector", // button icons
  ICO_SMALL_CLOSE = "ico_small_close", // button icons
  ICO_SOUND_HIGH = "ico_sound_high", // player icons
  ICO_SOUND_LOW = "ico_sound_low", // player icons
  ICO_SOUND_MUTE = "ico_sound_mute", // player icons
  ICO_SPACING = "ico_spacing", // editor icons
  ICO_STOP = "ico_stop", // player icons
  ICO_SUCCESS = "ico_success", // course attribute icons
  ICO_SUCCESS_FULL = "ico_success_full", // course attribute icons
  ICO_SURVEY = "ico_survey", // LO type icons
  ICO_SWITCH_OFF = "ico_switch_off", // component icons
  ICO_SWITCH_ON = "ico_switch_on", // component icons
  ICO_TEST = "ico_test", // LO type icons
  ICO_TRANSLATE = "ico_translate", // LO type icons
  ICO_UNDERLINE = "ico_underline", // course attribute icons
  ICO_UNDO = "ico_undo", // editor icons
  ICO_USER_STAR = "ico_user_star", // course attribute icons
  /**
   * ADMIN
   */
  ICO_ACCESS_DURATION = "ico_access_duration",
  ICO_ADDRESSBOOK = "ico_addressbook",
  ICO_ADD_BOOKING = "ico_add_booking",
  ICO_ADVANCEDSEARCH = "ico_advancedsearch",
  ICO_ADVICEMEETMEM = "ico_advicemeetmem",
  ICO_ADVICEMMPLUS = "ico_advicemmplus",
  ICO_ADVISOR = "ico_advisor",
  ICO_ADVISORYMEETING = "ico_advisorymeeting",
  ICO_ALERT = "ico_alert",
  ICO_ANSWER = "ico_answer",
  ICO_ARCHIVE = "ico_archive",
  ICO_ARROW_DOWN = "ico_arrow_down",
  ICO_ARROW_LEFT = "ico_arrow_left",
  ICO_ARROW_LEVEL_UP = "ico_arrow_level_up",
  ICO_ARROW_RIGHT = "ico_arrow_right",
  ICO_ARROW_UP = "ico_arrow_up",
  ICO_ATHOME = "ico_athome",
  ICO_ATTACHMENT = "ico_attachment",
  ICO_AUDIO = "ico_audio",
  ICO_BOOKING = "ico_booking",
  ICO_BOOKMARK = "ico_bookmark",
  ICO_CALENDAR_ADD = "ico_calendar_add",
  ICO_CART = "ico_cart",
  ICO_CERTIFICATE = "ico_certificate",
  ICO_CHANGEPASSWORD = "ico_changepassword",
  ICO_CHAT = "ico_chat",
  ICO_CHECKBOX_DES = "ico_checkbox_des",
  ICO_CHECKBOX_INDETERMINATE = "ico_checkbox_indeterminate",
  ICO_CHECKBOX_SEL = "ico_checkbox_sel",
  ICO_CLIENT = "ico_client",
  ICO_CLIENTS = "ico_clients",
  ICO_CLIENTEDIT = "ico_clientedit",
  ICO_CLIENTONBOARDING = "ico_clientonboarding",
  ICO_CLOCK = "ico_clock",
  ICO_COGS = "ico_cogs",
  ICO_COLLAPSEALL = "ico_collapseall",
  ICO_COLLAPSEGROUPS = "ico_collapsegroups",
  ICO_COMMENT = "ico_comment",
  ICO_COMMUNITY_GROUP = "ico_community_group",
  ICO_CONFIGURATION = "ico_configuration",
  ICO_COURSES = "ico_courses",
  ICO_COURSE_PLANNER_OVERVIEW = "ico_course_planner_overview",
  ICO_COURSESPOSTS = "ico_coursesposts",
  ICO_CREDITCARD = "ico_creditcard",
  ICO_DASHBOARD = "ico_dashboard",
  ICO_DELETE = "ico_delete",
  ICO_DOUBLEARROW = "ico_doublearrow",
  ICO_DOUBLESELECTOR = "ico_doubleselector",
  ICO_DOWN = "ico_down",
  ICO_DOWNLOAD_DOC = "ico_download_doc",
  ICO_EDITDOCUMENT = "ico_editdocument",
  ICO_EMPTY_ASSIGNMENTS = "ico_empty_assignments",
  ICO_EXCEL = "ico_excel",
  ICO_EXITFULLSCREEN = "ico_exitfullscreen",
  ICO_EXPANDALL = "ico_expandall",
  ICO_EXPANDGROUPS = "ico_expandgroups",
  ICO_FAILED = "ico_failed",
  ICO_FAVORITECOURSES = "ico_favoritecourses",
  ICO_FILTER = "ico_filter",
  ICO_FORWARD = "ico_forward",
  ICO_FULLSCREEN = "ico_fullscreen",
  ICO_HEART = "ico_heart",
  ICO_HELP = "ico_help",
  ICO_HIDEUNHIDE = "ico_hideunhide",
  ICO_HIDEUNHIDE_HIDDEN = "ico_hideunhide_hidden",
  ICO_IMAGE = "ico_image",
  ICO_INACTIVE = "ico_inactive",
  ICO_INTERNET = "ico_internet",
  ICO_IN_PLANNING = "ico_in_planning",
  ICO_ISSMIDTERMBOND = "ico_issmidtermbond",
  ICO_LEARNDOC_SIGNED_MORE = "ico_learndoc_signed_more",
  ICO_LEARNINGSTORE = "ico_learningstore",
  ICO_LEARNOBJECTPLUS = "ico_learnobjectplus",
  ICO_LEARNOBJECTS = "ico_learnobjects",
  ICO_LEFT = "ico_left",
  ICO_LOADMORE = "ico_loadmore",
  ICO_LOCATION = "ico_location",
  ICO_LOGIN = "ico_login",
  ICO_LOGOUT = "ico_logout",
  ICO_MAIL_RESET = "ico_mail_reset",
  ICO_MAIL_SETUP = "ico_mail_setup",
  ICO_MAKEANOTICE = "ico_makeanotice",
  ICO_MORE_HORIZ = "ico_more_horiz",
  ICO_MYCOURSES = "ico_mycourses",
  ICO_NAVIGATION = "ico_navigation",
  ICO_NAV_BACK = "ico_nav_back",
  ICO_NAV_MOBILE = "ico_nav_mobile",
  ICO_NEWCOURSES = "ico_newcourses",
  ICO_NOTIFICATION = "ico_notification",
  ICO_NOTIFICATION_ON = "ico_notification_on",
  ICO_PDF = "ico_pdf",
  ICO_PLUS_CIRCLE = "ico_plus_circle",
  ICO_PPT = "ico_ppt",
  ICO_PREFERENCES = "ico_preferences",
  ICO_PRINT = "ico_print",
  ICO_PROGRESS_0 = "ico_progress_0",
  ICO_PROGRESS_10 = "ico_progress_10",
  ICO_PROGRESS_20 = "ico_progress_20",
  ICO_PROGRESS_30 = "ico_progress_30",
  ICO_PROGRESS_40 = "ico_progress_40",
  ICO_PROGRESS_50 = "ico_progress_50",
  ICO_PROGRESS_60 = "ico_progress_60",
  ICO_PROGRESS_70 = "ico_progress_70",
  ICO_PROGRESS_80 = "ico_progress_80",
  ICO_PROGRESS_90 = "ico_progress_90",
  ICO_QUESTION = "ico_question",
  ICO_QUESTIONANSWER = "ico_questionanswer",
  ICO_RADIOBUTTON_DES = "ico_radiobutton_des",
  ICO_RADIOBUTTON_SEL = "ico_radiobutton_sel",
  ICO_REFRESH = "ico_refresh",
  ICO_REMOVEGROUP = "ico_removegroup",
  ICO_REPLY = "ico_reply",
  ICO_RULES = "ico_rules",
  ICO_RIGHT = "ico_right",
  ICO_SCHEDULER = "ico_scheduler",
  ICO_SECONDSORTASC = "ico_secondsortasc",
  ICO_SECONDSORTDES = "ico_secondsortdes",
  ICO_SECURE = "ico_secure",
  ICO_SENT_MESSAGE = "ico_sent_message",
  ICO_SESSION = "ico_session",
  ICO_SHOWINFOSEPLUS = "ico_showinfoseplus",
  ICO_SLH = "ico_slh",
  ICO_SORT_LIST_ASC = "ico_sort_list_asc",
  ICO_SORT_LIST_DESC = "ico_sort_list_desc",
  ICO_SMILEY_FROWN = "ico_smiley_frown",
  ICO_SMILEY_LAUGH = "ico_smiley_laugh",
  ICO_SMILEY_SMILE = "ico_smiley_smile",
  ICO_SMILEY_STRAIGHT = "ico_smiley_straight",
  ICO_TABLEVIEW = "ico_tableview",
  ICO_TAG = "ico_tag",
  ICO_THUMBDOWN = "ico_thumbdown",
  ICO_THUMBUP = "ico_thumbup",
  ICO_TIPP = "ico_tipp",
  ICO_TOGGLE_OFF = "ico_toggle_off",
  ICO_TOGGLE_ON = "ico_toggle_on",
  ICO_UP = "ico_up",
  ICO_UPLOAD = "ico_upload",
  ICO_UPLOAD_DOC = "ico_upload_doc",
  ICO_USERPROFILE = "ico_userprofile",
  ICO_VALIDITY_PERIOD = "ico_validity_period",
  ICO_VIDEO = "ico_video",
  ICO_WORD = "ico_word",
  ICO_ZERO_GHOST = "ico_zero_ghost",
  ICO_ZERO_SUPERVISOR = "ico_zero_supervisor",

  /** 120 Icons */
  GF_ACCOUNT_CANCEL = "gf_account_cancel",
  GF_ACCOUNT_LINK = "gf_account_link",
  GF_ACCOUNT_SUCCESS = "gf_account_success",
  GF_ACCOUNT_WAITING = "gf_account_waiting",
  GF_COURSE_CERTIFICATE = "gf_course_certificate",
  GF_COURSE_COACH_MSG = "gf_course_coach_msg",
  GF_COURSE_COACH_SUCCESS = "gf_course_coach_sucess",
  GF_COURSE_EMPTY = "gf_course_empty",
  GF_COURSE_LOS_EMPTY = "gf_course_los_empty",
  GF_COURSE_NOTIFICATION = "gf_course_notification",
  GF_COURSE_PAYMENT = "gf_course_payment",
  GF_COURSE_SIGNIN = "gf_course_signin",
  GF_EVENT_ADD = "gf_event_add",
  GF_EVENT_CANCEL = "gf_event_cancel",
  GF_EVENT_CLASSROOM_SUCCESS = "gf_event_classroom_success",
  GF_EVENT_COACH_ADD = "gf_event_coach_add",
  GF_EVENT_COACH_CANCEL = "gf_event_coach_cancel",
  GF_EVENT_DELIVERY_CANCEL = "gf_event_delivery_cancel",
  GF_EVENT_SUBSCRIBE = "gf_event_subscribe",
  GF_EVENT_SUCCESS = "gf_event_success",
  GF_EVENT_UPDATE_SUCCESS = "gf_event_update_success",
  GF_MAIL_FAIL = "gf_mail_fail",
  GF_MAIL_SUCCESS = "gf_mail_success",
  GF_MAIL_VOUCHER = "gf_mail_voucher",
  GF_POSTS_ARRIVED = "gf_posts_arrived",
  GF_POSTS_EMPTY = "gf_posts_empty",
  GF_SECURITY_APPROVE = "gf_security_approve",
  GF_SECURITY_CANCEL = "gf_security_cancel",
  GF_SECURITY_MSG = "gf_security_msg",
  GF_SECURITY_REFRESH = "gf_security_refresh",
  GF_SECURITY_SUCCESS = "gf_security_success",
  GF_SKILL_ADD = "gf_skill_add",
  GF_SKILL_EMPTY = "gf_skill_empty",
  GF_SKILL_NOTIFICATION = "gf_skill_notification",
  GF_SKILL_SUCCESS = "gf_skill_success",
  GF_SYS_ERROR = "gf_sys_error",
  GF_SYS_OFF = "gf_sys_off",
  GF_SYS_LOST = "gf_sys_lost",
}
