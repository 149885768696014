// eslint-disable-next-line import/prefer-default-export
export enum IconTypeAdminNavigation {
  NAV_ADMIN_ANALYTICS = "nav_admin_analytics",
  NAV_ADMIN_CODES = "nav_admin_codes",
  NAV_ADMIN_DISCLAIMER = "nav_admin_disclaimer",
  NAV_ADMIN_HELP_PAGE = "nav_admin_help_page",
  NAV_ADMIN_LOGIN = "nav_admin_login",
  NAV_ADMIN_MAIL_OVERVIEW = "nav_admin_mail_overview",
  NAV_ADMIN_MAIL_TPL = "nav_admin_mail_tpl",
  NAV_ADMIN_MAIL_DETAIL = "nav_admin_mail_detail",
  NAV_ADMIN_PASSWORD_POLICY = "nav_admin_password_policy",
  NAV_ADMIN_PROPERTIES = "nav_admin_properties",
  NAV_ADMIN_SETTINGS = "nav_admin_settings",
  NAV_ADMIN_STATISTICS = "nav_admin_statistics",
  NAV_ADMIN_SYSTEM_CONFIG = "nav_admin_system_config",
  NAV_ADMIN_UI = "nav_admin_ui",
  NAV_ADMIN_UI_OVERVIEW = "nav_admin_ui_overview",
  NAV_ADMIN_USER_OVERVIEW = "nav_admin_user_overview",
  NAV_ADMIN_USERS = "nav_admin_users",
}
