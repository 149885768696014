
import {css} from 'lit';
// eslint-disable-next-line import/prefer-default-export
export const style = css`:host,
.icon-wrapper {
  display: inline-block;
  outline: none;
}
:host svg,
.icon-wrapper svg {
  max-width: 100%;
  outline: none;
}
:host span,
.icon-wrapper span {
  line-height: 0;
  display: flex;
}

.icon-wrapper {
  display: inline-block;
}

:host span:focus, :host span:visited,
:host svg:focus, :host svg:visited,
:host g:focus, :host g:visited {
  outline: none;
}
:host svg.primary {
  fill: var(--slh-color-primary, #3B87D4);
}
:host svg.success {
  fill: var(--slh-color-success, #00B894);
}
:host svg.info {
  fill: var(--slh-color-info, #74B9FF);
}
:host svg.warning {
  fill: var(--slh-color-warning, #FDCB6E);
}
:host svg.error {
  fill: var(--slh-color-error, #FF7676);
}

:host(:focus-visible) span {
  display: block;
  position: relative;
}
:host(:focus-visible) span::after {
  content: "";
  display: block;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  position: absolute;
  top: -4px;
  left: -4px;
  border: 2px solid #4C49D4;
  border-radius: 4px;
}
`;
