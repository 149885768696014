import { IconTypeAdminNavigation } from "./admin-navigation.type";

export default function getIconContent(
  iconType: IconTypeAdminNavigation | undefined
): string | undefined {
  switch (iconType) {
    case IconTypeAdminNavigation.NAV_ADMIN_ANALYTICS:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_713_1586)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4462 21.032L23.9497 22.5355L22.5355 23.9497L21.032 22.4462C20.4365 22.7981 19.7418 23 19 23C16.7909 23 15 21.2091 15 19C15 16.7909 16.7909 15 19 15C21.2091 15 23 16.7909 23 19C23 19.7418 22.7981 20.4365 22.4462 21.032ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 3H3C2.44772 3 2 3.44772 2 4V20C2 20.5523 2.44772 21 3 21H13.3414C13.1203 20.3744 13 19.7013 13 19C13 17.223 13.7725 15.6264 15 14.5278V10H17V13.3414C17.6256 13.1203 18.2987 13 19 13C20.0929 13 21.1175 13.2922 22 13.8027V4C22 3.44772 21.5523 3 21 3ZM7 17V13H9V17H7ZM11 17V7H13V17H11Z"/>
          </g>
          <defs>
            <clipPath id="clip0_713_1586">
              <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_CODES:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM6 7V17H9V7H6ZM10 7V17H12V7H10ZM13 7V17H14V7H13ZM15 7V17H18V7H15Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_DISCLAIMER:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path d="M18 23V21H20V23H18Z"/>
          <path d="M18 20V15.5H20V20H18Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_HELP_PAGE:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path d="M17.7568 23V21.6667H19.201V23H17.7568Z"/>
          <path d="M20.6048 19.5878C20.2795 20.06 19.7813 20.4085 19.201 20.57V21H17.7568V20C17.7568 19.8232 17.8329 19.6536 17.9683 19.5286C18.1037 19.4036 18.2874 19.3333 18.4789 19.3333C18.684 19.3333 18.8849 19.2795 19.0583 19.1782C19.2316 19.0769 19.3701 18.9322 19.4579 18.7611C19.5456 18.5899 19.5789 18.3992 19.5539 18.2112C19.5288 18.0233 19.4465 17.8457 19.3164 17.6993C19.1863 17.5528 19.0139 17.4435 18.8192 17.384C18.6244 17.3245 18.4154 17.3172 18.2164 17.3631C18.0174 17.409 17.8366 17.5062 17.695 17.6432C17.5534 17.7803 17.4569 17.9516 17.4167 18.1373L16 17.8753C16.1148 17.3464 16.4245 16.8706 16.8765 16.5287C17.3284 16.1868 17.8946 16 18.4789 16C19.085 16.0017 19.6703 16.2043 20.1282 16.571C20.586 16.9376 20.886 17.4439 20.9735 17.9976C21.061 18.5514 20.9302 19.1157 20.6048 19.5878Z"/>  
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_LOGIN:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path d="M20 20H16C16 20 16 18.625 16 18H20V16L23 19L20 22V20Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_MAIL_DETAIL:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.24283 6.85419L11.4895 1.30843C11.8062 1.11848 12.2019 1.11855 12.5185 1.30862L21.7573 6.85416C21.9079 6.94453 22 7.10726 22 7.28286V19.9998C22 20.5521 21.5523 20.9998 21 20.9998H3C2.44772 20.9998 2 20.5521 2 19.9998V7.28298C2 7.10732 2.09218 6.94454 2.24283 6.85419ZM18.3456 8.24367L12.0606 13.6827L5.64722 8.23752L4.35278 9.76213L12.0731 16.3169L19.6544 9.75599L18.3456 8.24367Z" />
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_MAIL_OVERVIEW:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_648_364)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 13.4982C21.2304 13.1772 20.3859 13 19.5 13C15.9101 13 13 15.9101 13 19.5C13 20.0163 13.0602 20.5185 13.1739 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V13.4982ZM5.648 6.238L12.06 11.683L18.346 6.244L19.654 7.757L12.073 14.317L4.353 7.762L5.648 6.238Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4462 21.032L23.9497 22.5355L22.5355 23.9497L21.032 22.4462C20.4365 22.7981 19.7418 23 19 23C16.7909 23 15 21.2091 15 19C15 16.7909 16.7909 15 19 15C21.2091 15 23 16.7909 23 19C23 19.7418 22.7981 20.4365 22.4462 21.032ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"/>
          </g>
          <defs>
            <clipPath id="clip0_648_364">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
          </defs>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_MAIL_TPL:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M22 14.044C21.2389 13.6947 20.3922 13.5 19.5 13.5C16.1863 13.5 13.5 16.1863 13.5 19.5C13.5 20.018 13.5656 20.5206 13.689 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V14.044ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829ZM19.5 21.75L16.855 23.1406L17.3601 20.1953L15.2202 18.1094L18.1775 17.6797L19.5 15L20.8225 17.6797L23.7798 18.1094L21.6399 20.1953L22.145 23.1406L19.5 21.75Z" />
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_PASSWORD_POLICY:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path d="M21.3333 18.8H21.6667C21.8508 18.8 22 18.9567 22 19.15V22.65C22 22.8433 21.8508 23 21.6667 23H16.3333C16.1492 23 16 22.8433 16 22.65V19.15C16 18.9567 16.1492 18.8 16.3333 18.8H16.6667V18.45C16.6667 17.0969 17.7113 16 19 16C20.2887 16 21.3333 17.0969 21.3333 18.45V18.8ZM20.6667 18.8V18.45C20.6667 17.4835 19.9205 16.7 19 16.7C18.0795 16.7 17.3333 17.4835 17.3333 18.45V18.8H20.6667Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_PROPERTIES:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19 22H5C3.34315 22 2 20.6569 2 19V3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V15H22V19C22 20.6569 20.6569 22 19 22ZM18 17V19C18 19.5523 18.4477 20 19 20C19.5523 20 20 19.5523 20 19V17H18ZM9 7V9H15V7H9ZM5 9V7H7V9H5ZM5 11V13H7V11H5ZM9 13V11H15V13H9ZM9 17V15H15V17H9ZM5 15V17H7V15H5Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_SETTINGS:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1511 20.1165C16.0751 19.7089 16.0751 19.2911 16.1511 18.8835L15 18.2242L16.1352 16.2758L17.2863 16.935C17.6019 16.6672 17.9663 16.4569 18.3648 16.3162V15H20.6352V16.3162C21.0337 16.4569 21.3981 16.6672 21.7137 16.935L22.8648 16.2758L24 18.2242L22.8489 18.8835C22.9249 19.2911 22.9249 19.7089 22.8489 20.1165L24 20.7758L22.8648 22.7242L21.7137 22.065C21.3957 22.3353 21.0301 22.5451 20.6352 22.6838V24H18.3648V22.6838C17.9699 22.5451 17.6043 22.3353 17.2863 22.065L16.1352 22.7242L15 20.7758L16.1511 20.1165ZM20.3027 20.2955C20.0898 20.5065 19.8011 20.625 19.5 20.625C19.1989 20.625 18.9102 20.5065 18.6973 20.2955C18.4844 20.0845 18.3648 19.7984 18.3648 19.5C18.3648 19.2016 18.4844 18.9155 18.6973 18.7045C18.9102 18.4935 19.1989 18.375 19.5 18.375C19.8011 18.375 20.0898 18.4935 20.3027 18.7045C20.5156 18.9155 20.6352 19.2016 20.6352 19.5C20.6352 19.7984 20.5156 20.0845 20.3027 20.2955Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_STATISTICS:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3ZM7 13V17H9V13H7ZM11 7V17H13V7H11ZM15 10V17H17V10H15Z" />
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_SYSTEM_CONFIG:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.5 13C20.3859 13 21.2304 13.1772 22 13.4982V4.00748C22 3.44892 21.556 3 21.0082 3H2.9918C2.45531 3 2 3.45107 2 4.00748V16.9925C2 17.5511 2.44405 18 2.9918 18H11V20H7V22H13.4982C13.2382 21.3766 13.0725 20.7039 13.0189 20H13L13.0147 19.9411C13.005 19.7953 13 19.6482 13 19.5C13 15.9101 15.9101 13 19.5 13ZM14 13L17 10L14 7L13 8L15 10L13 12L14 13ZM10 13L11 12L9 10L11 8L10 7L7 10L10 13Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1511 20.1165C16.0751 19.7089 16.0751 19.2911 16.1511 18.8835L15 18.2242L16.1352 16.2758L17.2863 16.935C17.6019 16.6672 17.9663 16.4569 18.3648 16.3162V15H20.6352V16.3162C21.0337 16.4569 21.3981 16.6672 21.7137 16.935L22.8648 16.2758L24 18.2242L22.8489 18.8835C22.9249 19.2911 22.9249 19.7089 22.8489 20.1165L24 20.7758L22.8648 22.7242L21.7137 22.065C21.3957 22.3353 21.0301 22.5451 20.6352 22.6838V24H18.3648V22.6838C17.9699 22.5451 17.6043 22.3353 17.2863 22.065L16.1352 22.7242L15 20.7758L16.1511 20.1165ZM20.3027 20.2955C20.0898 20.5065 19.8011 20.625 19.5 20.625C19.1989 20.625 18.9102 20.5065 18.6973 20.2955C18.4844 20.0845 18.3648 19.7984 18.3648 19.5C18.3648 19.2016 18.4844 18.9155 18.6973 18.7045C18.9102 18.4935 19.1989 18.375 19.5 18.375C19.8011 18.375 20.0898 18.4935 20.3027 18.7045C20.5156 18.9155 20.6352 19.2016 20.6352 19.5C20.6352 19.7984 20.5156 20.0845 20.3027 20.2955Z"/>
      </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_UI:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path d="M19.5 15C21.9849 15 24 16.7901 24 19C23.9996 19.663 23.7361 20.2987 23.2673 20.7674C22.7985 21.2361 22.1627 21.4996 21.4998 21.4998H20.6151C20.2002 21.4998 19.865 21.8351 19.865 22.25C19.865 22.4399 19.9401 22.6149 20.0548 22.7449C20.175 22.8799 20.2502 23.055 20.2502 23.2498C20.2502 23.6652 19.905 24 19.5 24C17.0151 24 15 21.9849 15 19.5C15 17.0151 17.0151 15 19.5 15ZM17.475 19.5C17.654 19.5 17.8257 19.4289 17.9523 19.3023C18.0789 19.1757 18.15 19.004 18.15 18.825C18.15 18.646 18.0789 18.4743 17.9523 18.3477C17.8257 18.2211 17.654 18.15 17.475 18.15C17.296 18.15 17.1243 18.2211 16.9977 18.3477C16.8711 18.4743 16.8 18.646 16.8 18.825C16.8 19.004 16.8711 19.1757 16.9977 19.3023C17.1243 19.4289 17.296 19.5 17.475 19.5ZM21.525 19.5C21.704 19.5 21.8757 19.4289 22.0023 19.3023C22.1289 19.1757 22.2 19.004 22.2 18.825C22.2 18.646 22.1289 18.4743 22.0023 18.3477C21.8757 18.2211 21.704 18.15 21.525 18.15C21.346 18.15 21.1743 18.2211 21.0477 18.3477C20.9211 18.4743 20.85 18.646 20.85 18.825C20.85 19.004 20.9211 19.1757 21.0477 19.3023C21.1743 19.4289 21.346 19.5 21.525 19.5ZM19.5 18.15C19.679 18.15 19.8507 18.0789 19.9773 17.9523C20.1039 17.8257 20.175 17.654 20.175 17.475C20.175 17.296 20.1039 17.1243 19.9773 16.9977C19.8507 16.8711 19.679 16.8 19.5 16.8C19.321 16.8 19.1493 16.8711 19.0227 16.9977C18.8961 17.1243 18.825 17.296 18.825 17.475C18.825 17.654 18.8961 17.8257 19.0227 17.9523C19.1493 18.0789 19.321 18.15 19.5 18.15Z"/>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_UI_OVERVIEW:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M22.4462 21.032L23.9497 22.5355L22.5355 23.9497L21.032 22.4462C20.4365 22.7981 19.7418 23 19 23C16.7909 23 15 21.2091 15 19C15 16.7909 16.7909 15 19 15C21.2091 15 23 16.7909 23 19C23 19.7418 22.7981 20.4365 22.4462 21.032ZM21 19C21 20.1046 20.1046 21 19 21C17.8954 21 17 20.1046 17 19C17 17.8954 17.8954 17 19 17C20.1046 17 21 17.8954 21 19Z"/>
       </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_USER_OVERVIEW:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_648_382)">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 7C6 10.315 8.685 13 12 13C15.315 13 18 10.315 18 7C18 3.685 15.315 1 12 1C8.685 1 6 3.685 6 7ZM12 14C7.58172 14 4 17.5817 4 22H13.4982C13.1772 21.2304 13 20.3859 13 19.5C13 17.5735 13.8381 15.8427 15.1696 14.6525C14.1977 14.2326 13.126 14 12 14ZM23.9497 22.5355L22.4462 21.032C22.7981 20.4365 23 19.7418 23 19C23 16.7909 21.2091 15 19 15C16.7909 15 15 16.7909 15 19C15 21.2091 16.7909 23 19 23C19.7418 23 20.4365 22.7981 21.032 22.4462L22.5355 23.9497L23.9497 22.5355ZM19 21C20.1046 21 21 20.1046 21 19C21 17.8954 20.1046 17 19 17C17.8954 17 17 17.8954 17 19C17 20.1046 17.8954 21 19 21Z"/>
        </g>
        <defs>
          <clipPath id="clip0_648_382">
          <rect width="24" height="24" fill="white"/>
          </clipPath>
        </defs>
        </svg>
      </g>`;
    case IconTypeAdminNavigation.NAV_ADMIN_USERS:
      return `<g id="${iconType}">
        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 13.1739C15.1333 13.8511 13 16.4264 13 19.5C13 20.3859 13.1772 21.2304 13.4982 22H5C4.20435 22 3.44129 21.6839 2.87868 21.1213C2.31607 20.5587 2 19.7956 2 19V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V13.1739ZM6 9V7H14V9H6ZM6 13V11H14V13H6ZM6 17V15H11V17H6Z"/>
          <path d="M16 24C16 23.0907 16.3687 22.2186 17.0251 21.5756C17.6815 20.9327 18.5717 20.5714 19.5 20.5714C20.4283 20.5714 21.3185 20.9327 21.9749 21.5756C22.6313 22.2186 23 23.0907 23 24H16ZM19.5 20.1429C18.0497 20.1429 16.875 18.9921 16.875 17.5714C16.875 16.1507 18.0497 15 19.5 15C20.9503 15 22.125 16.1507 22.125 17.5714C22.125 18.9921 20.9503 20.1429 19.5 20.1429Z"/>
        </svg>
      </g>`;
    default:
      // eslint-disable-next-line no-console
      console.error(
        `[slh-icon] The icon ${iconType} does not exist in admin-navigation family.`
      );
      return "";
  }
}
